import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  try {
    const response = await API.get(
      `${URL.navigation}?populate[navigation][populate]=logo,menuItems,cta,socialItems.image`
    );
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};
