import { API, URL } from "src/config/api";

export const getBanner = async () => {
  try {
    const response = await API.get(
      `${URL.bookNowBanner}?populate[banner][populate]=*`
    );
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const getForm = async () => {
  try {
    const response = await API.get(
      `${URL.bookNowForm}?populate[form][populate]=*`
    );
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const sendForm = async (data) => {
  try {
    const response = await API.post(`${URL.email}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};
