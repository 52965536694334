import React from "react";
import { Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import {} from "./style";

const Loader = ({ height, size }) => {
  const bg = useColorModeValue("white", "navy.dark");
  const spinnerColor = useColorModeValue("blue.light", "blue.light");

  return (
    <Flex
      flex={1}
      flexDirection="column"
      justify="center"
      align="center"
      w="100%"
      h={height || "100vh"}
      bg={bg}
    >
      <Spinner size={size || "xl"} color={spinnerColor} />
    </Flex>
  );
};

export default Loader;
