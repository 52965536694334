import { SET_APP_DATA, SET_APP_LOADING } from "src/redux/constants";

//services
import { getData as getNavigation } from "src/services/api/navigation";
import { getData as getFooter } from "src/services/api/footer";
import {
  getBanner as getBookNowBanner,
  getForm as getBookNowForm,
} from "src/services/api/book-now";
import { getData as getCategories } from "src/services/api/category";
import { getData as getTreatments } from "src/services/api/treatments";

export const getAppData = () => async (dispatch) => {
  let appData = {};
  await getNavigation()
    .then(
      (response) => (appData.navigation = response.data.attributes.navigation)
    )
    .catch((err) => console.log(err));
  await getFooter()
    .then((response) => (appData.footer = response.data.attributes.footer))
    .catch((err) => console.log(err));
  await getCategories()
    .then((response) => (appData.categories = response.data))
    .catch((err) => console.log(err));
  await getTreatments()
    .then((response) => (appData.treatments = response.data))
    .catch((err) => console.log(err));
  await getBookNowBanner()
    .then(
      (response) => (appData.bookNowBanner = response.data.attributes.banner)
    )
    .catch((err) => console.log(err));
  await getBookNowForm()
    .then((response) => (appData.bookNowForm = response.data.attributes.form))
    .catch((err) => console.log(err));
  dispatch({
    type: SET_APP_DATA,
    payload: appData,
  });
  dispatch({
    type: SET_APP_LOADING,
    payload: false,
  });
};
