import ray from "src/config/ray";

const config = {
  axiosBaseUrl: `${process.env.REACT_APP_URL}/api`,
  baseUrl: process.env.REACT_APP_URL,
  production: process.env.REACT_APP_ENV === "production" ? true : false,
  // staticDirectory: process.env.REACT_APP_CDN,
  version: "Beta 1.0",
  ray,
  map: {
    position: { lat: 59.9281229, lng: 10.715143 },
  },
};

export default config;
