const url = {
  auth: {
    otp: {
      captcha: "auth/otp/captcha",
      login: "auth/otp/login",
      check: "auth/otp/checkotp",
      again: "auth/otp/sendagain",
    },
    credentials: {
      login: "auth/credentials/login",
      register: "auth/credentials/register",
    },
  },
  users: "users",
  home: "home",
  navigation: "navigation",
  footer: "footer",
  contactUs: "contact-us",
  aboutUs: "about-us",
  treatments: "treatments",
  services: "services",
  category: "categories",
  bookNowBanner: "book-now-banner",
  bookNowForm: "book-now-form",
  email: "email",
  price: "price-list",
  news: "news",
  newsArchive: "news-archive",
  products: "products",
  productsArchive: "product-archive",
};

export default url;
