const routes = [
  {
    directory: "home",
    url: "/",
    private: false,
    index: false,
  },
  {
    directory: "single-price",
    url: "/pris",
    params: ":slug",
    private: false,
    index: false,
  },
  {
    directory: "search",
    url: "/search",
    private: false,
    index: false,
  },
  {
    directory: "prices",
    url: "/pris",
    private: false,
    index: false,
  },
  {
    directory: "products",
    url: "/produkter",
    private: false,
    index: false,
  },
  {
    directory: "news",
    url: "/news",
    private: false,
    index: false,
  },
  {
    directory: "single-treatment",
    url: "kategori",
    params: ":slug",
    private: false,
    index: false,
  },
  {
    directory: "single-service",
    url: "/behandlinger",
    params: ":slug",
    private: false,
    index: false,
  },
  {
    directory: "single-product",
    url: "/produkter",
    params: ":slug",
    private: false,
    index: false,
  },
  {
    directory: "single-news",
    url: "/news",
    params: ":slug",
    private: false,
    index: false,
  },
  {
    directory: "about-us",
    url: "/about-us",
    private: false,
    index: false,
  },
  {
    directory: "contact-us",
    url: "/contact-us",
    private: false,
    index: false,
  },
];

export default routes;
