import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  try {
    const response = await API.get(
      `${URL.footer}?populate[footer][populate]=logo,favoriteTreatments,cta,socialItems.image,contactUsItems`
    );
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};
