import { mode } from "@chakra-ui/theme-tools";
import { colors } from "src/theme/colors";

export const globalStyles = {
  initialColorMode: "light",
  useSystemColorMode: false,
  // direction: "rtl",
  colors,
  fonts: {
    boldHeading: "montserrat-bold",
    extraBoldHeading: "montserrat-black",
    lightBody: "montserrat-light",
    regularBody: "montserrat-regular",
    sacremento: "sacremento",
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("white", colors.navy.dark)(props),
      },
      "h1, h2, h3, h4, h5, h6": {
        fontFamily: "montserrat-bold",
      },
      "ol, ul, li, p ,span": {
        fontFamily: "montserrat-regular",
      },
    }),
  },
};
