import { API, URL } from "src/config/api";

export const getData = async (slug) => {
  try {
    const response = await API.get(
      `${URL.treatments}?populate[0]=image&populate[1]=services.treatment&populate[2]=services.image&populate[3]=services.services&populate[4]=services.services.image&sort[0]=title`
    );
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const getSingleData = async (slug) => {
  try {
    const response = await API.get(`${URL.treatments}/${slug}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};
