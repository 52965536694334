import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  try {
    const response = await API.get(`${URL.category}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};
