import { mode } from "@chakra-ui/theme-tools";

export const tableStyles = {
  components: {
    Table: {
      variants: {
        primary: (props) => ({
          tr: {
            _odd: {
              bg: mode("white", "navy.dark")(props),
            },
            _even: {
              bg: mode("sky.light", "navy.light")(props),
            },
          },
        }),
      },
    },
  },
};
