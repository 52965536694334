export const colors = {
  white: "#ffffff",
  black: "#000000",
  primary: {
    50: "#d8fdff",
    100: "#abf1ff",
    200: "#7ce7ff",
    300: "#4adcfd",
    400: "#9ec0cc",
    500: "#04b8e2",
    600: "#008fb1",
    700: "#00667f",
    800: "#003e4e",
    900: "#00161e",
    main: "#95BAC7B2",
  },
  secondary: {
    50: "#e7f5fa",
    100: "#d0dbe0",
    200: "#b6c1c8",
    300: "#9aa9b1",
    400: "#7f909a",
    500: "#667781",
    600: "#4e5c65",
    700: "#374249",
    800: "#1f282e",
    900: "#040e16",
    main: "#374249",
  },
  navy: { dark: "#101c30", light: "#17243d" },
  sky: { dark: "#dae9f7", light: "#eff7ff" },
  red: { dark: "#a42d44", light: "#ca2241" },
  green: { dark: "#11534d", light: "#2f726b" },
  yellow: { dark: "#8a8328", light: "#cabb00" },
  blue: { dark: "#205aaa", light: "#3488fc" },
  orange: { dark: "#9d7627", light: "#ce9d14" },
  darkGray: { dark: "#34495E", light: "#40617e" },
  lightGray: { dark: "#BEC3C7", light: "#f1f1f1" },
};
