import { mode } from "@chakra-ui/theme-tools";

export const textAreaStyles = {
  components: {
    Textarea: {
      variants: {
        primary: (props) => ({
          bg: mode("sky.light", "navy.light")(props),
          color: mode("black", "white")(props),
          width: "100%",
          height: "200px",
          padding: "20px",
          lineHeight: "28px",
          borderRadius: "md",
          _placeholder: {
            color: mode("#979797", "#4f5e6c")(props),
          },
          _disabled: {
            opacity: 0.5,
          },
        }),
      },
    },
  },
};
